import React, { useState } from 'react';
import {
  Create,
  Edit,
  Toolbar,
  SimpleForm,
  TextInput,
  List,
  Datagrid,
  TextField,
  Button,
  ReferenceManyField,
  DeleteButton,
  TabbedForm,
  FormTab,
  translate,
  SaveButton,
  SelectField,
  ResettableTextField,
  useDataProvider,
  useRefresh,
  useNotify,
  useUpdate,
} from 'react-admin';
import { Grid, Box, TextField as MTextField } from '@material-ui/core';
import ReferenceAssessmentTable from './ReferenceAssessmentTable';
import { useTranslate } from 'ra-core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import DataGridArrangableBody from './DataGridArrangableBody';
import { auth } from './utils/nhost';

export const AssessmentTemplateList = translate(({ translate, ...props }) => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick="edit" isRowSelectable={() => false}>
      <TextField source="template_name"></TextField>
    </Datagrid>
  </List>
));

export const AssessmentTemplateCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="template_name" />
    </SimpleForm>
  </Create>
);

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const AddNewColumnToolbar = ({ record, ...props }) => {
  const classes = useStyles();
  const [state, setState] = useState({
    newColumnText: '',
    newColumnType: 'text',
  });
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  return (
    <Box>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={3} md={2}>
          <ResettableTextField
            label={translate(
              'resources.assessment_template_columns.fields.column_name'
            )}
            value={state.newColumnText}
            onChange={(ev) =>
              setState({ ...state, newColumnText: ev.target.value })
            }
          />
        </Grid>
        <Grid item xs={3} md={3}>
          <FormControl className={classes.formControl} variant="filled">
            <InputLabel id="new-column-type-label">Type</InputLabel>
            <Select
              labelId="new-column-type-label"
              id="new-column-type"
              value={state.newColumnType}
              onChange={(ev) =>
                setState({ ...state, newColumnType: ev.target.value })
              }
            >
              <MenuItem value="text">{translate('quality.text')}</MenuItem>
              <MenuItem value="probability">
                {translate('quality.probability')}
              </MenuItem>
              <MenuItem value="consequence">
                {translate('quality.consequence')}
              </MenuItem>
            </Select>
          </FormControl>
          {/*<SelectField choices={[{id: 'probability'}, {id: 'consequence'}]} optionText="id" value={null}/>*/}
        </Grid>
        <Grid item xs={3} md={3}>
          <SaveButton
            label="ra.action.add"
            handleSubmitWithRedirect={(data) => {
              dataProvider
                .create('assessment_template_columns', {
                  data: {
                    assessment_template_id: record.id,
                    column_name: state.newColumnText,
                    column_type:
                      (state.newColumnType !== 'text' && state.newColumnType) ||
                      null,
                  },
                })
                .then(() => refresh());
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const MoveButton = ({ up, ...props }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const moveColumn = (record) => {
    /*
    const filter = up ? '_lt' : '_gt';
    dataProvider
      .getList('assessment_template_columns', {
        sort: { field: 'order_index', order: up ? 'desc' : 'asc' },
        filter: {
          _and: [
            { [filter]: { order_index: orderIndex } },
            { template_id: templateId },
          ],
        },
      })
      .then(({ data, total }) => {
        console.log(
          'switching order_index ' +
            orderIndex +
            ' with order_index ' +
            data[0].order_index
        );
        // TODO!
      })
      .catch((error) => notify(error.message, 'error'));
      */
    dataProvider
      .getOne('assessment_template_columns', {
        id: up ? record.prevId : record.nextId,
      })
      .then((data1) => {
        console.log(
          'Updating id ' +
            data1.data.id +
            ' with orderIndex ' +
            record.order_index
        );
        console.dir(record);
        return dataProvider
          .update('assessment_template_columns', {
            id: data1.data.id,
            data: { order_index: record.order_index },
          })
          .then((data2) => {
            console.log('after update:');
            console.dir(data2);
            return dataProvider
              .update('assessment_template_columns', {
                id: record.id,
                data: { order_index: data1.data.order_index },
              })
              .then(() => refresh())
              .catch((error) => notify(error.message));
          })
          .catch((error) => notify(error.message));
      })
      .catch((error) => notify(error.message));
  };
  const label = 'quality.' + (up ? 'up' : 'down');
  return (
    (((up && props.record.prevId) || (!up && props.record.nextId)) && (
      <Button
        // component={Link}
        label={label}
        onClick={(ev) => {
          ev.stopPropagation();
          moveColumn(props.record);
        }}
      >
        {up ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
      </Button>
    )) ||
    null
  );
};

const EditableTextField = ({ record, ...props }) => {
  const [editValue, setEditValue] = useState();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const role = auth.getClaim('x-hasura-default-role');
  return (
    (editValue !== undefined && role === 'superuser' && (
      <>
        <MTextField
          value={editValue}
          onChange={(ev) => setEditValue(ev.target.value)}
          autoFocus={true}
        />
        <Button
          onClick={() => {
            //notify(`updating column_name with id ${record.id} with value ${editValue}`);
            dataProvider
              .update('assessment_template_columns', {
                id: record.id,
                data: { column_name: editValue },
              })
              .then(() => setEditValue(undefined))
              .catch((error) => notify(error.message));
          }}
          label="ra.action.save"
        ></Button>
        <Button
          onClick={() => {
            setEditValue(undefined);
          }}
          label="ra.action.cancel"
        ></Button>
      </>
    )) || (
      <TextField
        {...props}
        source="column_name"
        record={record}
        onClick={(ev) => setEditValue(record.column_name)}
      />
    )
  );
};

export const AssessmentTemplateEdit = (props) => {
  const [editColumnId, setEditColumnId] = useState(null);
  const [editColumnValue, setEditColumnValue] = useState(null);
  const role = auth.getClaim('x-hasura-default-role');
  return (
    <Edit {...props} undoable={false}>
      <TabbedForm
        toolbar={
          <Toolbar>
            <></>
          </Toolbar>
        }
      >
        <FormTab label="quality.data">
          <ReferenceAssessmentTable
            rows="assessment_template_rows"
            columns="assessment_template_columns"
            cells="assessment_template_values"
            target="assessment_template_id"
            readOnly={role !== 'superuser'}
          />
        </FormTab>
        <FormTab label="quality.columns">
          <ReferenceManyField
            addLabel={false}
            reference="assessment_template_columns"
            target="assessment_template_id"
            sort={{ field: 'order_index', order: 'ASC' }} // necessary due to missing id column
          >
            <Datagrid body={<DataGridArrangableBody />}>
              {/*<TextField source="id" />*/}
              <EditableTextField source="column_name" />
              {/*<Button
                label="ra.action.edit"
                onClick={(ev) => {
                  ev.stopPropagation();
                  setEditColumnId();
                }}
              >
                <EditIcon />
              </Button>
              */}
              <TextField source="column_type" />
              {role === 'superuser' && <MoveButton up={true} />}
              {role === 'superuser' && <MoveButton up={false} />}
              {role === 'superuser' && (
                <DeleteButton
                  undoable={false}
                  redirect={`#${props.location.search}`}
                />
              )}
            </Datagrid>
          </ReferenceManyField>
          {role === 'superuser' && <AddNewColumnToolbar />}
        </FormTab>
        <FormTab label="quality.properties">
          {(role === 'superuser' && <TextInput source="template_name" />) || (
            <TextField source="template_name" />
          )}
          {role === 'superuser' && <Toolbar />}
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
