const RiskMatrix = ({ probabilities, consequences }) => {
  const sums = probabilities
    .map((probability, i) => `${probability}-${consequences[i]}`)
    .reduce(
      (acc, currentValue) => ({
        ...acc,
        [String(currentValue)]: (acc[String(currentValue)] || 0) + 1,
      }),
      {}
    );
  return (
    <div className="risk-matrix">
      <div className="axis">
        <svg className="y-axis" viewBox="0 0 10 150">
          <g>
            <line
              x1="5"
              x2="5"
              y1="0"
              y2="150"
              stroke="black"
              strokeWidth="1"
            />
            <line
              x1="0"
              x2="10"
              y1="25"
              y2="25"
              stroke="black"
              strokeWidth="1"
            />
            <line
              x1="0"
              x2="10"
              y1="74"
              y2="74"
              stroke="black"
              strokeWidth="1"
            />
            <line
              x1="0"
              x2="10"
              y1="121"
              y2="121"
              stroke="black"
              strokeWidth="1"
            />
          </g>
        </svg>
        {/*
        <svg className="origo" viewBox="0 0 10 10">
          <g>
            <line x1="5" x2="5" y1="0" y2="5" stroke="black" stroke-width="1" />
            <line x1="5" x2="9" y1="5" y2="5" stroke="black" stroke-width="1" />
          </g>
        </svg>
        */}
        <svg className="x-axis" viewBox="0 0 150 10">
          <g>
            <line
              x1="0"
              x2="150"
              y1="5"
              y2="5"
              stroke="black"
              strokeWidth="1"
            />
            <line
              x1="30"
              x2="30"
              y1="0"
              y2="10"
              stroke="black"
              strokeWidth="1"
            />
            <line
              x1="80"
              x2="80"
              y1="0"
              y2="10"
              stroke="black"
              strokeWidth="1"
            />
            <line
              x1="128"
              x2="128"
              y1="0"
              y2="10"
              stroke="black"
              strokeWidth="1"
            />
          </g>
        </svg>
      </div>
      <table>
        <tbody>
          {[3, 2, 1].map((probability) => (
            <tr key={probability}>
              {[1, 2, 3].map((consequence) => {
                const key = `${probability}-${consequence}`;
                const product = probability * consequence;
                return (
                  <td
                    key={key}
                    className={
                      product > 4 ? 'red' : product < 3 ? 'green' : 'yellow'
                    }
                  >
                    {sums[key]}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
      <br />
    </div>
  );
};
export default RiskMatrix;
