const domainMessages = {
  en: {
    // react admin overrides:
    resources: {},
    // application specific texts
    quality: {},
  },
  no: {
    // react admin overrides:
    resources: {
      assessments: {
        name: 'Kartlegging |||| Kartlegginger',
        fields: {
          assessment_name: 'Kartleggingsnavn',
          assessment_template_id: 'Mal',
        },
      },
      assessment_templates: {
        name: 'Mal |||| Maler',
        fields: {
          template_name: 'Mal',
        },
      },
      assessment_template_columns: {
        name: 'Kolonner',
        fields: {
          column_name: 'Kolonnenavn',
          column_type: 'Kolonnetype',
        },
      },
    },
    // application specific texts
    quality: {
      properties: 'Egenskaper',
      columns: 'Kolonner',
      data: 'Data',
      up: 'Opp',
      down: 'Ned',
      text: 'Tekst',
      probability: 'Sannsynlighet',
      consequence: 'Konsekvens',
    },
  },
};
export default domainMessages;
