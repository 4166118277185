import React from 'react';
import {
  Create,
  Edit,
  Toolbar,
  SimpleForm,
  TextInput,
  List,
  Datagrid,
  TextField,
  translate,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  TabbedForm,
  FormTab,
  useDataProvider,
  useRedirect,
  useRefresh,
  useNotify,
  useQueryWithStore,
} from 'react-admin';
import ReferenceAssessmentTable from './ReferenceAssessmentTable';
import { auth } from './utils/nhost';

export const AssessmentList = translate(({ translate, ...props }) => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      {/*<TextField source="id" />*/}
      <TextField source="assessment_name" />
      <ReferenceField
        source="assessment_template_id"
        reference="assessment_templates"
        link={false}
      >
        <TextField source="template_name" />
      </ReferenceField>
    </Datagrid>
  </List>
));

/*
const useStyles = makeStyles({
  button: {
    margin: '2em 0',
    color: '#fff',
    borderRadius: 0,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 16,
    paddingRight: 16,
  },
});
const AssessmentCreateToolbar = props => {
  const classes = useStyles();
  return (
    <Toolbar {...props}>
      <SaveButton
        label="save Assessment"
        className={classes.button}
        submitOnEnter={true}
      />
    </Toolbar>
  );
};
*/

export const AssessmentCreate = (props) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const notify = useNotify();

  const onSuccess = ({ data: assessmentData, ...props }) => {
    dataProvider
      .getManyReference('assessment_template_rows', {
        target: 'assessment_template_id',
        id: assessmentData.assessment_template_id,
      })
      .then(({ data: templateRowsData }) => {
        // console.log('Creating...');
        return Promise.all(
          templateRowsData.map((templateRow) =>
            // console.log('Creating row ') || console.dir(templateRow) ||
            dataProvider
              .create('assessment_rows', {
                data: { assessment_id: assessmentData.id },
              })
              .then(({ data: assessmentRow }) =>
                dataProvider
                  .getManyReference('assessment_template_values', {
                    target: 'row_id',
                    id: templateRow.id,
                  })
                  .then(({ data: templateValuesData }) =>
                    Promise.all(
                      templateValuesData.map((templateValue) =>
                        // console.log('Creating value ') || console.dir(templateValue) ||
                        dataProvider.create('assessment_values', {
                          data: {
                            row_id: assessmentRow.id,
                            column_id: templateValue.column_id,
                            text_value: templateValue.text_value,
                            assessment_id: assessmentData.id,
                          },
                        })
                      )
                    )
                  )
              )
          )
        );
      })
      .then(() => {
        redirect('/assessments');
        refresh();
      })
      .catch(
        (error) =>
          console.error(error.message) ||
          notify('ra.notification.data_provider_error')
      );
  };

  return (
    <Create onSuccess={onSuccess} {...props}>
      <SimpleForm /*toolbar={<AssessmentCreateToolbar />} */ redirect="list">
        <ReferenceInput source="organisation_id" reference="organisations">
          <SelectInput optionText="organisation_name" />
        </ReferenceInput>

        <ReferenceInput
          source="assessment_template_id"
          reference="assessment_templates"
        >
          <SelectInput optionText="template_name" />
        </ReferenceInput>
        <TextInput source="assessment_name" />
      </SimpleForm>
    </Create>
  );
};

export const AssessmentEdit = (props) => {
  const {
    data: organisation,
    loading: organisationLoading,
    error: organisationError,
  } = useQueryWithStore({
    type: 'getOne',
    resource: 'organisations',
    payload: {
      id: props?.record?.id,
    },
  });

  const FormTabHeader = (props) => (
    <div
      style={{
        width: 'inherit',
        marginTop: '-48px',
        marginBottom: '48px',
      }}
    >
      <div style={{ float: 'right' }}>{organisation?.organisation_name}</div>
      <div style={{ clear: 'both' }}></div>
    </div>
  );

  const role = auth.getClaim('x-hasura-default-role');

  return (
    <Edit {...props} undoable={false}>
      <TabbedForm
        toolbar={
          <Toolbar>
            <></>
          </Toolbar>
        }
      >
        <FormTab label="quality.data">
          <FormTabHeader />
          <ReferenceAssessmentTable
            rows="assessment_rows"
            columns="assessment_template_columns"
            cells="assessment_values"
            target="assessment_id"
            columnsTarget="assessment_template_id"
            columnsTargetValue={(record) => record.assessment_template_id}
            readOnly={role === 'viewer'}
          />
        </FormTab>
        <FormTab label="quality.properties">
          {(role !== 'viewer' && <TextInput source="assessment_name" />) || (
            <TextField source="assessment_name" />
          )}
          {role !== 'viewer' && <Toolbar />}
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
