import React, { useState } from 'react';
import {
  useQueryWithStore,
  Loading,
  Error,
  SaveButton,
  useRefresh,
  useDataProvider,
  useNotify,
} from 'react-admin';
import ExcelJS from 'exceljs';
import GetAppIcon from '@material-ui/icons/GetApp';
import { makeStyles } from '@material-ui/core/styles';
import RiskMatrix from './RiskMatrix';

const ReferenceAssessmentTable = ({
  rows,
  columns,
  cells,
  target,
  record,
  columnsTarget,
  columnsTargetValue,
  readOnly,
  ...props
}) => {
  const [editMode, setEditMode] = useState(false);
  const [editColumnIndex, setEditColumnIndex] = useState(null);
  const [editRowIndex, setEditRowIndex] = useState(null);
  const [editCellValues, setEditCellValues] = useState({});

  const {
    data: columnsData,
    loading: columnsLoading,
    error: columnsError,
  } = useQueryWithStore({
    type: 'getManyReference',
    resource: columns,
    payload: {
      id: (columnsTargetValue && columnsTargetValue(record)) || record.id,
      target: columnsTarget || target,
      sort: { field: 'order_index', order: 'asc' },
    },
  });

  const {
    data: rowsData,
    loading: rowsLoading,
    error: rowsError,
  } = useQueryWithStore({
    type: 'getManyReference',
    resource: rows,
    payload: {
      id: record.id,
      target,
      sort: { field: 'order_index', order: 'asc' },
    },
  });

  const {
    data: cellsData,
    loading: cellsLoading,
    error: cellsError,
  } = useQueryWithStore({
    type: 'getManyReference',
    resource: cells,
    payload: { id: record.id, target },
  });
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();
  const useStyles = makeStyles((theme) => ({}));

  /*
  const [save, { loading }] = useMutation({
    type: 'update',
    resource: 'comments',
    payload: { id: record.id, data: { isApproved: true } }
});
*/

  if (columnsLoading || rowsLoading || cellsLoading) return <Loading />;
  if (columnsError || rowsError || cellsError)
    return <Error error={columnsError || rowsError || cellsError} />;
  if (!columnsData || !rowsData || !cellsData) return null;

  const unsavedCellCount = Object.keys(editCellValues).flatMap(
    (col) => editCellValues[col]
  ).length;
  const cellIds = columnsData.reduce(
    (accumulator, column, columnIndex) => ({
      ...accumulator,
      [String(columnIndex)]: rowsData.reduce(
        (accumulator, row, rowIndex) => ({
          ...accumulator,
          [String(rowIndex)]: cellsData.find(
            (cell) => cell.column_id === column.id && cell.row_id === row.id
          )?.id,
        }),
        {}
      ),
    }),
    {}
  );

  const cellValues = rowsData.map((row, rowIndex) =>
    columnsData.map(
      (column, columnIndex) =>
        (editCellValues[String(columnIndex)] &&
          editCellValues[String(columnIndex)][String(rowIndex)]) ||
        ((!editCellValues[String(columnIndex)] ||
          editCellValues[String(columnIndex)][String(rowIndex)] !== '') &&
          cellsData.find(
            (cell) => cell.column_id === column.id && cell.row_id === row.id
          )?.text_value) ||
        ''
    )
  );

  const navigationHandler = (ev) => {
    if (ev.key === 'Tab' && !ev.shiftKey) {
      if (editColumnIndex < columnsData.length - 1) {
        ev.preventDefault();
        setEditColumnIndex(editColumnIndex + 1);
      } else if (editRowIndex < rowsData.length) {
        ev.preventDefault();
        setEditColumnIndex(0);
        setEditRowIndex(editRowIndex + 1);
      }
    }
    if (ev.key === 'Tab' && ev.shiftKey) {
      if (editColumnIndex > 0) {
        ev.preventDefault();
        setEditColumnIndex(editColumnIndex - 1);
      } else if (editRowIndex > 0) {
        ev.preventDefault();
        setEditColumnIndex(columnsData.length - 1);
        setEditRowIndex(editRowIndex - 1);
      }
    }
    if (ev.key === 'ArrowLeft' && ev.ctrlKey && editColumnIndex > 0) {
      ev.preventDefault();
      setEditColumnIndex(editColumnIndex - 1);
    }
    if (
      ev.key === 'ArrowRight' &&
      ev.ctrlKey &&
      editColumnIndex < columnsData.length - 1
    ) {
      ev.preventDefault();
      setEditColumnIndex(editColumnIndex + 1);
    }
    if (ev.key === 'ArrowUp' && ev.ctrlKey && editRowIndex > 0) {
      ev.preventDefault();
      setEditRowIndex(editRowIndex - 1);
    }
    if (
      ev.key === 'ArrowDown' &&
      ev.ctrlKey &&
      editRowIndex < rowsData.length
    ) {
      ev.preventDefault();
      setEditRowIndex(editRowIndex + 1);
    }
  };

  const cancelEdit = () => {
    setEditCellValues({
      ...editCellValues,
      [String(editColumnIndex)]: {
        ...editCellValues[String(editColumnIndex)],
        [String(editRowIndex)]: undefined,
      },
    });
    //setEditColumnIndex(null);
    //setEditRowIndex(null);
    setEditMode(false);
  };

  const probabilityColumnIndex = columnsData.findIndex(
    (col) => col.column_type === 'probability'
  );
  const consequenceColumnIndex = columnsData.findIndex(
    (col) => col.column_type === 'consequence'
  );

  const probabilities =
    probabilityColumnIndex >= 0 &&
    cellValues
      .map((row) => parseInt(row[probabilityColumnIndex], 10))
      .concat(
        editCellValues &&
          editCellValues[probabilityColumnIndex] &&
          parseInt(editCellValues[probabilityColumnIndex][rowsData.length], 10)
      );
  const consequences =
    consequenceColumnIndex >= 0 &&
    cellValues
      .map((row) => parseInt(row[consequenceColumnIndex], 10))
      .concat(
        editCellValues &&
          editCellValues[consequenceColumnIndex] &&
          parseInt(editCellValues[consequenceColumnIndex][rowsData.length], 10)
      );

  return (
    <>
      <table className="assessment-table">
        <thead>
          <tr>
            {columnsData.map((column, columnIndex) => (
              <th key={columnIndex}>{column.column_name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <>
            {rowsData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columnsData.map((column, columnIndex) => (
                  <td
                    key={`${rowIndex}-${columnIndex}`}
                    onClick={(ev) => {
                      if (
                        !editMode &&
                        columnIndex === editColumnIndex &&
                        rowIndex === editRowIndex
                      ) {
                        // setEditMode(true);
                      } else {
                        setEditColumnIndex(columnIndex);
                        setEditRowIndex(rowIndex);
                      }
                    }}
                    onDoubleClick={(ev) => {
                      setEditMode(true);
                    }}
                    tabIndex={
                      columnIndex === editColumnIndex &&
                      rowIndex === editRowIndex
                        ? 0
                        : undefined
                    }
                    onKeyUp={(ev) => {
                      if (!editMode && ev.key === 'Escape') {
                        setEditColumnIndex(null);
                        setEditRowIndex(null);
                      }
                    }}
                    className={
                      false &&
                      columnIndex === editColumnIndex &&
                      rowIndex === editRowIndex
                        ? 'selected'
                        : ''
                    }
                  >
                    {(() => {
                      const cellValue = cellValues[rowIndex][columnIndex];
                      const onChangeHandler = (ev) => {
                        setEditCellValues({
                          ...editCellValues,
                          [String(columnIndex)]: {
                            ...editCellValues[String(columnIndex)],
                            [String(rowIndex)]: ev.target.value,
                          },
                        });
                      };
                      return editMode &&
                        columnIndex === editColumnIndex &&
                        rowIndex === editRowIndex ? (
                        <>
                          {column.column_type === 'probability' ||
                          column.column_type === 'consequence' ? (
                            <select
                              value={cellValue}
                              onChange={onChangeHandler}
                              disabled={readOnly}
                            >
                              <option value=""></option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                            </select>
                          ) : (
                            <textarea
                              autoFocus
                              value={cellValue || ''}
                              rows={cellValue?.split('\n').length + 1 || 1}
                              disabled={readOnly}
                              onChange={onChangeHandler}
                              onKeyDown={navigationHandler}
                              onKeyUp={(ev) => {
                                if (ev.key === 'Escape') {
                                  cancelEdit();
                                } else if (
                                  ev.key === 'Enter' &&
                                  ev.ctrlKey === true
                                ) {
                                  // enter
                                  //setEditColumnIndex(null);
                                  //setEditRowIndex(null);
                                  setEditMode(false);
                                }
                              }}
                            />
                          )}
                          <div>
                            {!readOnly && (
                              <button onClick={() => setEditMode(false)}>
                                ✓
                              </button>
                            )}
                            <button onClick={() => cancelEdit()}>✕</button>
                          </div>
                        </>
                      ) : (
                        cellValue?.split('\n').map((p, i) => (
                          <React.Fragment key={i}>
                            {p}
                            <br />
                          </React.Fragment>
                        ))
                      );
                    })()}
                  </td>
                ))}
              </tr>
            ))}
            <tr className="new">
              {columnsData.map((column, columnIndex) => (
                <td
                  key={`new-${columnIndex}`}
                  onClick={(ev) => {
                    setEditColumnIndex(columnIndex);
                    setEditRowIndex(rowsData.length);
                  }}
                  onDoubleClick={(ev) => {
                    if (!readOnly) setEditMode(true);
                  }}
                >
                  {(() => {
                    const cellValue =
                      editCellValues &&
                      editCellValues[String(columnIndex)] &&
                      editCellValues[String(columnIndex)][
                        String(rowsData.length)
                      ];
                    const onChangeHandler = (ev) => {
                      setEditCellValues({
                        ...editCellValues,
                        [String(columnIndex)]: {
                          ...editCellValues[String(columnIndex)],
                          [String(rowsData.length)]: ev.target.value,
                        },
                      });
                    };
                    return editMode &&
                      columnIndex === editColumnIndex &&
                      rowsData.length === editRowIndex ? (
                      <>
                        {column.column_type === 'probability' ||
                        column.column_type === 'consequence' ? (
                          <select value={cellValue} onChange={onChangeHandler}>
                            <option value=""></option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                          </select>
                        ) : (
                          <textarea
                            autoFocus
                            value={cellValue || ''}
                            rows={cellValue?.split('\n').length + 1 || 1}
                            onChange={onChangeHandler}
                            onKeyDown={navigationHandler}
                            onKeyUp={(ev) => {
                              if (ev.key === 'Escape') {
                                // escape
                                setEditCellValues({
                                  ...editCellValues,
                                  [String(columnIndex)]: {
                                    ...editCellValues[String(columnIndex)],
                                    [String(rowsData.length)]: undefined,
                                  },
                                });
                                setEditColumnIndex(null);
                                setEditRowIndex(null);
                              } else if (
                                ev.key === 'Enter' &&
                                ev.ctrlKey === true
                              ) {
                                // enter
                                setEditColumnIndex(null);
                                setEditRowIndex(null);
                              }
                            }}
                          />
                        )}
                        <div>
                          <button onClick={() => setEditMode(false)}>✓</button>
                          <button onClick={() => cancelEdit()}>✕</button>
                        </div>
                      </>
                    ) : (
                      cellValue?.split('\n').map((p, i) => (
                        <React.Fragment key={i}>
                          {p}
                          <br />
                        </React.Fragment>
                      ))
                    );
                  })()}
                </td>
              ))}
            </tr>
          </>
        </tbody>
      </table>
      {unsavedCellCount > 0 && !readOnly && (
        <SaveButton
          handleSubmitWithRedirect={(data) => {
            // create new row if used
            new Promise((resolve, reject) => {
              console.log('creating row?');
              (Object.keys(editCellValues).find(
                (x) => editCellValues[x][String(rowsData.length)]
              ) &&
                dataProvider
                  .create(rows, { data: { [target]: record.id } })
                  .then(({ data }) => {
                    console.dir(data);
                    console.log('created row, returning new id ' + data.id);
                    resolve(data.id);
                  })) ||
                resolve();
            })
              .then((newRowId) => {
                // delete rows with all empty values
                const deletedRows = cellValues.flatMap((row, rowIndex) =>
                  row.filter((value) => value === '').length ===
                  columnsData.length
                    ? [rowIndex]
                    : []
                );
                console.log('deleteRows:');
                console.dir(deletedRows);
                return Promise.all(
                  deletedRows.map((rowIndex) => {
                    return Promise.all(
                      columnsData
                        .map(
                          (col, colIndex) =>
                            cellIds[String(colIndex)][String(rowIndex)]
                        )
                        .filter((x) => x)
                        // .map(x => console.log('about to delete cells from row '+rowIndex+' with id '+x) || x)
                        .map((id) => dataProvider.delete(cells, { id: id }))
                    ).then(
                      () =>
                        console.log(
                          'deleting row with id ' + rowsData[rowIndex].id
                        ) ||
                        dataProvider.delete(rows, { id: rowsData[rowIndex].id })
                    );
                  })
                ).then(() => {
                  return { newRowId, deletedRows };
                });
              })
              .then(({ newRowId, deletedRows }) =>
                Promise.all(
                  Object.keys(editCellValues).flatMap((colIndexStr) =>
                    Object.keys(editCellValues[colIndexStr])
                      .filter(
                        (rowIndexStr) =>
                          !deletedRows.find(
                            (x) => x === parseInt(rowIndexStr, 10)
                          )
                      )
                      // remove aborted edits with cell value undefined
                      .filter(
                        (rowIndexStr) =>
                          editCellValues[colIndexStr][rowIndexStr] !== undefined
                      )
                      .map(
                        (rowIndexStr) =>
                          (cellIds[colIndexStr][rowIndexStr] &&
                            dataProvider
                              .update(cells, {
                                id: cellIds[colIndexStr][rowIndexStr],
                                data: {
                                  text_value:
                                    editCellValues[colIndexStr][rowIndexStr],
                                },
                              })
                              .then((x) =>
                                console.log(
                                  `updated col ${colIndexStr} row ${rowIndexStr} with id ${cellIds[colIndexStr][rowIndexStr]}`
                                )
                              )) ||
                          //rowIndexStr<rowsData.length &&
                          dataProvider
                            .create(cells, {
                              data: {
                                text_value:
                                  editCellValues[colIndexStr][rowIndexStr],
                                row_id:
                                  rowIndexStr === String(rowsData.length)
                                    ? newRowId
                                    : rowsData[rowIndexStr].id,
                                column_id: columnsData[colIndexStr].id,
                                [target]: record.id,
                              },
                            })
                            .then((x) =>
                              console.log(
                                `created col ${colIndexStr} row ${rowIndexStr}`
                              )
                            )
                      )
                  )
                )
              )
              /*
              .then(() => {
                // attempt to find orphan rows and values
                dataProvider.getManyReference('assessment_template_rows', {target: 'assessment_template_id', id: record.id })
                .then(({data: rowsData}))
              })
              */
              .then(() =>
                // setEditCellValues({})
                refresh()
              )
              .catch((error) => notify(error.message, 'warning'));
          }}
          label="ra.action.save"
          style={{ marginTop: '1em', marginRight: '1em' }}
        />
      )}
      <SaveButton
        label="Excel"
        icon={<GetAppIcon />}
        style={{ marginTop: '1em' }}
        handleSubmitWithRedirect={async (data) => {
          const workbook = new ExcelJS.Workbook();
          // create a sheet with the first row and column frozen
          const worksheet = workbook.addWorksheet('Assessment', {
            views: [{ state: 'frozen', xSplit: 0, ySplit: 1 }],
          });
          worksheet.columns = columnsData.map((column) => ({
            style: {
              alignment: {
                wrapText: true,
                vertical: 'middle',
                // shrinkToFit: true
              },
            },
            width: 20,
            header: column.column_name,
          }));
          worksheet.getRow(1).font = { bold: true };
          const newRows = worksheet.addRows(cellValues);

          const buffer = await workbook.xlsx.writeBuffer();
          var a = document.createElement('a');
          var blob = new Blob([buffer], { type: 'application/x-vnd-excel' });
          var url = URL.createObjectURL(blob);
          a.setAttribute('href', url);
          a.setAttribute('download', 'rma-assessment.xlsx');
          a.click();
        }}
      />
      <br />
      <br />
      {probabilityColumnIndex >= 0 && consequenceColumnIndex >= 0 && (
        <RiskMatrix probabilities={probabilities} consequences={consequences} />
      )}
    </>
  );
};
export default ReferenceAssessmentTable;
