import React, { useState, useEffect } from 'react';
// import { ApolloClient, InMemoryCache } from '@apollo/client';
import { useApolloClient } from '@apollo/client';
import buildHasuraProvider from 'ra-data-hasura';
import { Admin, Resource } from 'react-admin';
import { createMuiTheme } from '@material-ui/core/styles';
import {
  AssessmentCreate,
  AssessmentEdit,
  AssessmentList,
} from './assessments.js';
import {
  AssessmentTemplateCreate,
  AssessmentTemplateEdit,
  AssessmentTemplateList,
} from './assessmentTemplates.js';
import norwegianMessages from 'ra-language-norwegian';
import englishMessages from 'ra-language-english';
// domain translations
// import * as domainMessages from './messages';
import domainMessages from './messages';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { auth } from './utils/nhost';
import authProvider from './utils/authProvider';

const messages = {
  no: { ...norwegianMessages, ...domainMessages.no },
  en: { ...englishMessages, ...domainMessages.en },
};

const i18nProvider = polyglotI18nProvider(
  (locale) => (locale === 'en' ? messages.en : messages.no),
  'no' // Default locale
);

export const theme = createMuiTheme({
  palette: {
    primary: {
      // light: '#F9A34F',
      light: '#55C1FF', // from logo
      main: '#006BAA', // 50% darkened from above
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      //light: '#F9A34F',
      main: '#CBD700',
      // dark: will be calculated from palette.secondary.main,
      // contrastText: '#383836'
    },
  },
  //shadows: ['none']
});

const App = () => {
  const [dataProvider, setDataProvider] = useState(null);
  const apolloClient = useApolloClient();

  useEffect(() => {
    const buildDataProvider = async () => {
      const dataProvider = await buildHasuraProvider({
        client: apolloClient,
      });
      setDataProvider(() => dataProvider);
    };
    buildDataProvider();
  }, []);

  if (!dataProvider) return <p>Loading...</p>;

  const role = auth.getClaim('x-hasura-default-role');

  return (
    <Admin
      dataProvider={dataProvider}
      theme={theme}
      i18nProvider={i18nProvider}
      authProvider={authProvider}
      loginPage={() => {
        window.location = '/login';
        return <div>Login</div>;
      }}
      // logoutButton={()=>auth.logout()}
    >
      <Resource
        name="assessments"
        list={AssessmentList}
        edit={AssessmentEdit}
        create={role !== 'viewer' && AssessmentCreate}
      />
      <Resource
        name="assessment_templates"
        // options={{ label: 'Templates' }}
        list={role !== 'viewer' && AssessmentTemplateList}
        edit={AssessmentTemplateEdit}
        create={AssessmentTemplateCreate}
      />
      <Resource name="assessment_template_columns" />
      <Resource name="assessment_template_rows" />
      <Resource name="assessment_template_values" />
      <Resource name="assessment_values" />
      <Resource name="organisations" />
    </Admin>
  );
};

export default App;
