import { auth } from './nhost';

const authProvider = {
  // authentication
  login: (params) => Promise.resolve(),
  checkError: (error) => Promise.resolve(),
  checkAuth: (params) =>
    auth.isAuthenticated() ? Promise.resolve() : Promise.reject(),
  logout: () => auth.logout(),
  getIdentity: () => Promise.resolve(),
  // authorization
  getPermissions: (params) => Promise.resolve(),
};
export default authProvider;
